.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 380px;
    margin-bottom: 28px;
  }

}
