@import './code.css';

.html-render {
  min-height: '16px';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.12px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(32, 64, 86, 1);
    margin: 14px 0 10px;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 17px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 13px;
  }

  .md img.js-lazy-loaded,
  .md img.emoji {
    min-width: inherit;
    min-height: inherit;
    background-color: inherit;
    max-width: 100%;
  }

  .md img:not(.emoji) {
    margin: 0 0 12px;
  }

  .md p {
    margin: 0 0 20px;
  }

  .md table:not(.code) {
    margin: 20px 0;
  }

  .md pre {
    padding: 12px;
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 1.6em;
    overflow-x: auto;
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }

  .md ul,
  .md ol {
    padding: 0;
    margin: 0 0 20px;
  }

  .md li {
    line-height: 1.6em;
    margin-left: 25px;
    padding-left: 3px;
  }

  .no-attachment-icon {
    // display: block;
    // text-align: center;
  }

  // .no-attachment-icon:after {
  //   content: attr(data-content);
  //   display: block;
  // }

  table {
    width: 100%;
    font-size: 14px;
    thead,
    tbody {
      tr {
        th,
        td {
          text-align: left;
          padding-left: 12px;
          padding-right: 12px;
          min-width: 50px;
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
        }
      }
    }
    thead {
      background-color: rgba(32, 64, 86, 0.202306);
      tr {
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          white-space: nowrap;
        }
      }
    }
    tbody {
      background-color: #eee;
      tr {
        border-bottom: 1px solid #fff;
        td {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }
}
