.dataSourceTypeItem {
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
    margin-right: 12px;
    margin-bottom: 16px;
    display: inline-block;
    text-align: center;
    width: 140px;
    height: 96px;
    cursor: pointer;
    position: relative;
    vertical-align: top;
}

.dataSourceTypeItemNotSupport {
    background-color: rgba(255, 255, 255, 0.756);
    box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.756);
    margin-right: 12px;
    margin-bottom: 16px;
    display: inline-block;
    text-align: center;
    width: 140px;
    height: 96px;
    cursor: not-allowed;
    position: relative;
    vertical-align: top;
}

.dataSourceTypeItemImg {
    width: 140px;
    height: 87px;
    display: block;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.dataSourceTypeItemName {
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    height: 30px;
    padding: 4px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.dataSourceTypeGroupTab {
    margin-top: 16px;
    margin-bottom: 16px;
    text-indent: 8px;
    border-left: 4px solid #00c1de;
    margin-right: 8px;
    vertical-align: top;
    line-height: 1.1;
    width: 100%;
    position: relative;
}

.dataSourceTypeGroupTabName {
    font-weight: 500;
    font-size: 16px;
}

.dataSourceTypeGroup {
    position: relative;
}