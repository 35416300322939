.card-item {
  color: #121212;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 100%;
  &:hover {
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.35);
  }
  .card-img {
    border-radius: 20px 20px 0 0;
  }
  .card-content {
    padding: 16px 26px 18px;
    .card-title {
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card-tags {
      margin-top: 12px;
      display: flex;
      font-size: 12px;
      > div + div {
        margin-left: 8px;
      }
    }
    .card-desc {
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 40px;
    }
    .card-date {
      font-size: 14px;
      line-height: 20px;
      color: #9d9d9d;
      margin-top: 20px;
    }
  }
}

.card-user-modal {
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-header {
      border-radius: 20px 20px 0 0;
      background: #1F4055;
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-close-x {
      color: #fff;
    }
  }
}
