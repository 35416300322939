.container {
    width: 330px;
    padding: 5px 15px;
    background-color: rgba(210, 238, 236, 0.589);
    height: 100%;
    overflow: auto;
    .colorLabel {
        margin-bottom: 0;
        .stateOne {
            display: inline-block;
            background-color: #f1c670;
            height: 11px;
            width: 11px;
        }
        .stateTwo {
            display: inline-block;
            background-color: #ebe8e1;
            height: 11px;
            width: 11px;
            margin-left: 6px;
        }
        .stateThree {
            display: inline-block;
            background-color: #bbbab8;
            height: 11px;
            width: 11px;
            margin-left: 6px;
        }
        .relStateOne {
            display: inline-block;
            background-color: #333333;
            height: 11px;
            width: 11px;
        }
        .relStateTwo {
            display: inline-block;
            background-color: #bbbab8;
            height: 11px;
            width: 11px;
            margin-left: 6px;
        }
        .relStateThree {
            display: inline-block;
            background-color: #3f9714;
            height: 11px;
            width: 11px;
            margin-left: 6px;
        }
    }
    .noBlank {
        margin-bottom: 5px;
    }
    .someblank {
        margin-top: 1rem;
    }
    .publish {
        margin-bottom: 10px;
    }
    .title {
        text-align: center;
        margin: 5px 0 10px;
        font-weight: 600;
    }
}