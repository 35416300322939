.custom-collapse {
  padding: 24px 48px;
  border-radius: 20px;
  background-color: #fff;
  .custom-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #204056;
    .custom-collapse-title {
      display: flex;
      align-items: center;
      margin-right: auto;
      font-size: 24px;
      font-weight: 500;
      .ant-avatar {
        background-color: #204056;
        vertical-align: middle;
        font-size: 20px;
        margin-right: 16px;
      }
    }
    .custom-collapse-link {
      margin-right: 48px;
      font-size: 18px;
      color: #00BCF2;
    }
  }
  .custom-collapse-content {
    margin-top: 24px;
  }
}
