.cellContainer {
    width: 80px;
    height: 50%;
    background-color: bisque;
    position: absolute;
    top: 0;
    left: 30px;
    border-radius: 20px;
    z-index: 100;
    transform: translateY(50%);
}