@keyframes blinking-dot {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinking-scroll-button {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes gl-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.code .file-line-num {
  min-width: 3rem;
}
.code .file-line-num::before {
  display: none;
  align-self: center;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  width: 0.75rem;
  height: 0.75rem;
  float: left;
  background-color: rgba(0, 0, 0, 0.3);
  /* -webkit-mask-image: url(/assets/icons-stacked-f55b5152b1b2d03ec4f8ea2eb2d4357a3a3d344c70df055648e44148b71ed95c.svg#link); */
  /* mask-image: url(/assets/icons-stacked-f55b5152b1b2d03ec4f8ea2eb2d4357a3a3d344c70df055648e44148b71ed95c.svg#link); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
  content: '';
}
.code .file-line-num:hover::before {
  display: inline-block;
}
.code .file-line-num:focus::before {
  display: inline-block;
}
.code .line-numbers,
.code .diff-line-num,
.code .code-search-line {
  background-color: #fafafa;
}
.code .line-numbers.conflict_marker,
.code .line-numbers.conflict_our,
.code .diff-line-num.conflict_marker,
.code .diff-line-num.conflict_our,
.code .code-search-line.conflict_marker,
.code .code-search-line.conflict_our {
  background-color: #e1fad7;
  border-color: #e1fad7;
}
.code .line-numbers.conflict_their,
.code .diff-line-num.conflict_their,
.code .code-search-line.conflict_their {
  background-color: #e0f0ff;
  border-color: #e0f0ff;
}
.code .diff-line-num,
.code .diff-line-num a {
  color: rgba(0, 0, 0, 0.3);
}
.code pre.code,
.code .diff-line-num {
  border-color: #f0f0f0;
}
.code,
.code pre.code,
.code .line_holder .line_content {
  background-color: #fff;
  color: #303030;
}
.code .line_expansion {
  background-color: #fafafa;
}
.code .line_expansion .diff-td,
.code .line_expansion td {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.code .line_expansion a {
  color: #1068bf;
}
.code .line_holder.match .line_content,
.code .line_holder .new-nonewline.line_content,
.code .line_holder .old-nonewline.line_content {
  color: rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}
.code
  .line_holder
  .diff-grid-left:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our),
.code
  .line_holder
  .diff-grid-right:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our),
.code
  .line_holder.code-search-line:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our) {
  background-color: #e1d8f9;
  border-color: #cbbbf2;
}
.code
  .line_holder
  .diff-grid-left:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our)
  a,
.code
  .line_holder
  .diff-grid-right:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our)
  a,
.code
  .line_holder.code-search-line:hover
  .diff-line-num:not(.empty-cell):not(.conflict_marker_their):not(.conflict_marker_our)
  a {
  color: #5e5e5e;
}
.code .line_holder .diff-line-num.old {
  background-color: #f9d7dc;
  border-color: #fac5cd;
}
.code .line_holder .diff-line-num.old a {
  color: #ae979a;
}
.code .line_holder .diff-line-num.new {
  background-color: #ddfbe6;
  border-color: #c7f0d2;
}
.code .line_holder .diff-line-num.new a {
  color: #9bb0a1;
}
.code .line_holder .diff-line-num.is-over,
.code .line_holder .diff-line-num.hll:not(.empty-cell).is-over {
  background-color: #e1d8f9;
  border-color: #cbbbf2;
}
.code .line_holder .diff-line-num.is-over a,
.code .line_holder .diff-line-num.hll:not(.empty-cell).is-over a {
  color: #5e5e5e;
}
.code .line_holder .diff-line-num.hll:not(.empty-cell) {
  background-color: #fbf2da;
  border-color: #f0e2bd;
}
.code .line_holder:not(.diff-expanded) + .diff-expanded > .diff-line-num,
.code .line_holder:not(.diff-expanded) + .diff-expanded > .line-coverage,
.code .line_holder:not(.diff-expanded) + .diff-expanded > .line_content,
.code
  .line_holder.diff-expanded
  + .line_holder:not(.diff-expanded)
  > .diff-line-num,
.code
  .line_holder.diff-expanded
  + .line_holder:not(.diff-expanded)
  > .line-coverage,
.code
  .line_holder.diff-expanded
  + .line_holder:not(.diff-expanded)
  > .line_content {
  border-top: 1px solid #e0e0e0;
}
.code .line_holder.diff-expanded > .diff-line-num,
.code .line_holder.diff-expanded > .line-coverage,
.code .line_holder.diff-expanded > .line_content {
  background: #f7f7f7;
  border-color: #f7f7f7;
}
.code .line_holder .line_content.old {
  background-color: #fbe9eb;
}
.code .line_holder .line_content.old::before {
  color: #ae979a;
}
.code .line_holder .line_content.old span.idiff {
  background-color: #fac5cd;
}
.code .line_holder .line_content.new {
  background-color: #ecfdf0;
}
.code .line_holder .line_content.new::before {
  color: #9bb0a1;
}
.code .line_holder .line_content.new span.idiff {
  background-color: #c7f0d2;
}
.code .line_holder .line_content.match {
  color: rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}
.code .line_holder .line_content.hll:not(.empty-cell) {
  background-color: #fcf8e7;
}
.code .line_holder .line-coverage {
  transition: border-left 0.1s ease-out;
}
.code .line_holder .line-coverage.coverage {
  border-left: 4px solid #2da160;
}
.code .line_holder .line-coverage.no-coverage {
  border-left: 2px solid #ec5941;
}
.code .line_holder .line-coverage.old,
.code .line_holder .line-codequality.old {
  background-color: #fbe9eb;
}
.code .line_holder .line-coverage.new,
.code .line_holder .line-codequality.new {
  background-color: #ecfdf0;
}
.code .line_holder .line-coverage.hll:not(.empty-cell),
.code .line_holder .line-codequality.hll:not(.empty-cell) {
  background-color: #fcf8e7;
}
.code pre .hll {
  background-color: #f8eec7 !important;
}
.code span.highlight_word {
  background-color: #fafe3d !important;
}
.code .line a {
  color: #0086b3;
}
.code .hll {
  background-color: #f8f8f8;
}
.code .c {
  color: #998;
  font-style: italic;
}
.code .err {
  color: #a61717;
  background-color: #e3d2d2;
}
.code .k {
  font-weight: 600;
}
.code .o {
  font-weight: 600;
}
.code .cm {
  color: #998;
  font-style: italic;
}
.code .cp {
  color: #999;
  font-weight: 600;
}
.code .c1 {
  color: #998;
  font-style: italic;
}
.code .cs {
  color: #999;
  font-weight: 600;
  font-style: italic;
}
.code .gd {
  color: #000;
  background-color: #fdd;
}
.code .gd .x {
  color: #000;
  background-color: #faa;
}
.code .ge {
  font-style: italic;
}
.code .gr {
  color: #a00;
}
.code .gh {
  color: #800080;
  font-weight: 600;
}
.code .gi {
  color: #000;
  background-color: #dfd;
}
.code .gi .x {
  color: #000;
  background-color: #afa;
}
.code .go {
  color: #888;
}
.code .gp {
  color: #555;
}
.code .gs {
  font-weight: 600;
}
.code .gu {
  color: #800080;
  font-weight: 600;
}
.code .gt {
  color: #a00;
}
.code .kc {
  font-weight: 600;
}
.code .kd {
  font-weight: 600;
}
.code .kn {
  font-weight: 600;
}
.code .kp {
  font-weight: 600;
}
.code .kr {
  font-weight: 600;
}
.code .kt {
  color: #458;
  font-weight: 600;
}
.code .m {
  color: #099;
}
.code .s {
  color: #d14;
}
.code .n {
  color: #333;
}
.code .na {
  color: #008080;
}
.code .nb {
  color: #0086b3;
}
.code .nc {
  color: #458;
  font-weight: 600;
}
.code .no {
  color: #008080;
}
.code .ni {
  color: #800080;
}
.code .ne {
  color: #900;
  font-weight: 600;
}
.code .nf {
  color: #900;
  font-weight: 600;
}
.code .nn {
  color: #555;
}
.code .nt {
  color: #000080;
}
.code .nv {
  color: #008080;
}
.code .ow {
  font-weight: 600;
}
.code .w {
  color: #bbb;
}
.code .mf {
  color: #099;
}
.code .mh {
  color: #099;
}
.code .mi {
  color: #099;
}
.code .mo {
  color: #099;
}
.code .sb {
  color: #d14;
}
.code .sc {
  color: #d14;
}
.code .sd {
  color: #d14;
}
.code .s2 {
  color: #d14;
}
.code .se {
  color: #d14;
}
.code .sh {
  color: #d14;
}
.code .si {
  color: #d14;
}
.code .sx {
  color: #d14;
}
.code .sr {
  color: #009926;
}
.code .s1 {
  color: #d14;
}
.code .ss {
  color: #990073;
}
.code .bp {
  color: #999;
}
.code .vc {
  color: #008080;
}
.code .vg {
  color: #008080;
}
.code .vi {
  color: #008080;
}
.code .il {
  color: #099;
}
.code .gc {
  color: #999;
  background-color: #eaf2f5;
}
.code .diff-line-num.conflict_marker_our,
.code .diff-line-num.conflict_our {
  background-color: #e1fad7;
  border-color: #e1fad7;
}
.code .diff-line-num.conflict_marker_their,
.code .diff-line-num.conflict_their {
  background-color: #e0f0ff;
  border-color: #e0f0ff;
}
.code .line_holder .line_content.conflict_marker_our,
.code .line_holder .line-coverage.conflict_marker_our {
  background-color: #e1fad7;
  border-color: #e1fad7;
}
.code .line_holder .line_content.conflict_marker_their,
.code .line_holder .line-coverage.conflict_marker_their {
  background-color: #e0f0ff;
  border-color: #e0f0ff;
}
.code .line_holder .line_content.conflict_our,
.code .line_holder .line-coverage.conflict_our {
  background-color: #effdec;
  border-color: #effdec;
}
.code .line_holder .line_content.conflict_their,
.code .line_holder .line-coverage.conflict_their {
  background-color: #f2f9ff;
  border-color: #f2f9ff;
}
