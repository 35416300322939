.user-collection {
  font-size: 16px;
  min-height: 460px;
  .user-collection-mark {
    color: #9d9d9d;
    margin-bottom: 16px;
  }
  .user-collection-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 44px;
  }

  .ant-form-item-label > label {
    font-size: 16px;
    color: #565960;
  }
  .ant-input {
    font-size: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
  }

  .ant-input-textarea-show-count:after {
    color: #bcbdc0;
    font-size: 12px;
    position: absolute;
    bottom: 24px;
    right: 10px;
    margin-top: -20px;
  }

  .ant-form-item-label > label:after {
    margin-right: 16px;
  }

  .ant-btn {
    color: #1f4055!important;
  }

  .ant-btn-primary {
    border-radius: 100px;
    color: #fff!important;
    border-color: #1f4055!important;
    background-color: #1f4055!important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:hover {
    color: #fff;
    border: 0;
    background-color: #a7a9aa;
    text-shadow: none;
    box-shadow: none;
  }
}
