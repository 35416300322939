.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.nodePool {
    width: 100%;
    flex: 1;
}
.nodeItem {
    margin: 15px 0 15px 5px;
}