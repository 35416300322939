.text-radio {
  color: #9D9D9D;
  cursor: pointer;
  margin-right: 60px;
  font-size: 18px;
  display: inline-block;
}
.text-radio-selected {
  color: #1F4055;
  font-weight: 500;
}
