.attribute-upsert-modal {
  min-width: 840px;
}

.left-pane {
  position: relative;
  padding-right: 8px;

  .left-pane-table-body {
    max-height: 389px;
    overflow-y: auto;
  }
}

.left-pane-header {
  padding-left: 2em;
  margin-bottom: 3px;
  font-weight: bold;

  .left-pane-header-cell {
    display: inline-block;
    white-space: nowrap;
    /*border: 1px solid silver;*/
    margin-right: 4px;
  }
}

.attribute-entry {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  height: 35px;

  &:hover {
    background-color: rgba(225, 233, 255, 0.64);
  }

  .attribute-entry-data {
    flex: none;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    position: relative;
    &:hover {
      overflow: visible;
      .label {
        display: inline-block;
        position: absolute;
        padding: 3px 5px;
        border: 3px solid #333;
        background-color: #fff;
        z-index: 3;
        transform: translate(-7px, -50%);
      }
    }
  }
}

.right-pane {
  position: relative;
  min-width: 21em;

  .form-area {
    flex: 1;
    padding: 0 12px 0 8px;
  }
  .warning-area {
    border-top: 1px solid #dbdbdb;
    max-height: 100px;
  }
}

.type-options {
  display: flex;
  .type-name {

  }
  .type-code {
    margin-left: auto;
    color: #cbcbcb
  }
}

.drag-handle {
  color: rgba(138, 138, 138, 0.7);
  width: 1.5em;
  padding: 3px;
  margin-left: 5px;
  cursor: move;
}

.delete-button {
  padding: 3px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
}
