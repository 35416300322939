.custom-anchor {
  .custom-anchor-group {
    background-color: #fff !important;
    border-radius: 20px;
    padding: 24px 12px 14px;
  }
  .ant-anchor-ink {
    display: none;
  }
  .ant-anchor-link {
    font-size: 16px;
    padding-left: 6px;
    margin-bottom: 10px;
    .ant-avatar {
      background-color: #9d9d9d;
    }
    & > .ant-anchor-link-title {
      color: #9d9d9d;
      display: flex;
      align-items: center;
    }
  }
  .ant-anchor-link-active {
    font-weight: 500;
    .ant-avatar {
      background-color: #00bcf2;
    }
    & > .ant-anchor-link-title {
      color: #00bcf2;
    }
  }
}
