.left-n-right {
  border: 2px solid silver;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 8px;
  background-color: #f5f5f5;

  .select-block-style {
    width: 100%;
  }

}

.entity-list-item-name {

}
.entity-list-item-desc {
  color: #aaaaaa;
}

.relation-config-content {

  .acc-n-attr {
    display: flex;
    flex-direction: column;

    .type-option-label {}
    .type-option-desc {
      min-height: 2.5em;
      line-height: 1.1;
      color: #aaaaaa;
    }

    .acc-panel {
      padding: 8px;
      flex: none;

      .acc-desc {
        .acc-desc-label {
          line-height: 30px;
          span {
            padding: 3px;
            font-weight: bold;
          }
        }
      }
    }
    .attr-panel {
      position: relative;
      padding: 8px;
      border-top: 1px solid #d9d9d9;

      .left-pane-table-body {
        max-height: 200px;
        overflow-y: auto;
      }
    }
  }

  .type-n-warning {
    padding: 8px;
    display: flex;
    flex-direction: column;

    .form-area {
      flex: 1;
      padding: 0 12px 0 8px;
    }
    .warning-area {
      border-top: 1px solid #dbdbdb;
      max-height: 100px;
    }
  }
}
