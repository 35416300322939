.technology-content {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  .technology-group {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      .technology-group-content {
        border-right: 1px solid #e5e5e5;
      }
    }
    .technology-group-name {
      padding: 0 30px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .technology-group-content {
      flex: 1;
      padding: 0 30px;
      .technology-group-item {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .ant-checkbox-wrapper {
          color: #9d9d9d;
          .ant-checkbox-checked + span {
            color: #00bcf2;
          }
        }
      }
    }
  }
}

.technology-title {
  display: flex;
  align-items: center;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    color: #00bcf2;
  }
  .technology-icon {
    margin-right: 8px;
    font-size: 28px;
  }
  .technology-text {
    font-size: 18px;
    font-weight: 500;
  }
}
.technology-selected {
  color: #00bcf2;
}

.ant-popover .ant-popover-arrow {
  display: none;
}
