body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  border: 0;
}

.day4-block[data-name='container-qC4_gdJ90'] {
  height: calc(100% - 48px);
}

.day4-block[data-name='graphiQL-KTiED95NWY'] .resultWrap {
  width: 100%;
  overflow: auto;
}

.day4-block[data-name='container-KqR5z9ju3-'] {
  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
    color: #ffffff;
  }
  .ant-menu-submenu-arrow,
  .ant-menu-submenu-expand-icon {
    color: #ffffff;
  }
}

#root {
  .day4-block {
    > .ant-input-affix-wrapper,
    > .ant-input,
    > .ant-btn {
      border-radius: 100px;
    }

    .ant-btn {
      color: #1f4055;
    }

    .ant-btn-primary {
      color: #fff;
      border-color: #1f4055;
      background-color: #1f4055;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:active,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:hover {
      color: #fff;
      border: 0;
      background-color: #a7a9aa;
      text-shadow: none;
      box-shadow: none;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00bcf2 !important;
  border-color: #00bcf2 !important;
}

.ant-checkbox-checked::after {
  border-radius: 20px !important;
}

.ant-checkbox-inner {
  border-radius: 20px !important;
}

::selection {
  background: #00bcf2 !important;
}

#cky2i10mj00003f6ckz7k842t {
  height: 310px;
}
#cky2m5yar00013f6cksl3ztdv {
  margin-top: 52px;
}

#cky2igscm00013f6cr2p03trx {
  margin-top: 50px;
}

#cky1ehqn200033f6c4j38ed0w, #cky9fo5he00003968hn7wyls5 {
  height: 70px;
}

.ant-avatar .ant-avatar-string {
  left: 51%;
}

.day4-block[data-name='button-dYMcDM_VU'] .ant-btn {
  color: #1890ff!important;
  border: 2px solid #1890ff!important;
  border-radius: 4px!important;
}
